import { Controller } from "@hotwired/stimulus";
import { list } from "postcss";
import {
    getMetaValue,
    findElement,
    removeElement,
    insertAfter,
  } from "../helpers/dropzone";

export default class extends Controller {
    get headers() {
        return { "X-CSRF-Token": getMetaValue("csrf-token") };
      }

    initialize() {
        //register DZ
        const { Dropzone } = require("dropzone");

        let item_attachments_dropzoneOptions = {
            headers: { 
                "X-CSRF-Token": getMetaValue("csrf-token"),
            },
            url: '/',
            method: "POST",
            credentials: "same-origin",
            maxFiles: 5,
            uploadMultiple: true,
            parallelUploads: 5,
            dictDefaultMessage: 'Drop Here!',
            //paramName: "attachments",
            maxFilesize: 200, // MB
            acceptedFiles: 'image/jpeg,image/jpg,image/png,image/gif,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,video/mp4,video/3gpp,video/quicktime,video/x-msvideo,video/x-ms-wmv',
            autoProcessQueue: false,
            thumbnailWidth: 200,
            thumbnailHeight: 200,
            addRemoveLinks: false,
            clickable: [".item-files-dropzone"],
            //previewTemplate: document.querySelector('#message-attachments-upload-template').innerHTML,
            previewTemplate: document.querySelector('.dz-preview').innerHTML,
            previewsContainer: ".dropzone-previews",
            //previewsContainer: "#message-attachments-preview-container",
            init: function () {
                this.on("success", function (file) {
                    //console.log("success > " + file.name);
                });
                //console.log("CSRF: " + getMetaValue("csrf-token"));
            }
        };

        let item_attachments_dz = new Dropzone(".item-files-dropzone", item_attachments_dropzoneOptions);
        item_attachments_dz.on("drop", file => {
            //register_avatar_dz.removeAllFiles(true);
            //console.log(`File dropped: ${file.name}`);
        });
        
        item_attachments_dz.on("addedfile", file => {
            //console.log(`File added: ${file.name}`);
            //document.getElementById("message-attachments-info-container").classList.add("hidden");
        });

        item_attachments_dz.on("removedfile", file => {
            //console.log(`File removed: ${file.name}`);
            //document.getElementById("message-attachments-info-container").classList.remove("hidden");
        });

        item_attachments_dz.on("sending", function(file, xhr, formData) {
            //formData.append("authentication_token", getMetaValue("csrf-token"));
            //formData.append("chat_message[content]", document.getElementById("chat-text").value);
            //console.log(formData);
          });

        item_attachments_dz.on("queuecomplete", function (file) {
            //console.log("All files have uploaded ");
            //document.getElementById("chat-text").value = '';
            item_attachments_dz.removeAllFiles(true);
            //document.getElementById("message-attachments").classList.add("hidden");
            const attachment_list = document.getElementById("version-attachments-list");
            attachment_list.innerHTML = "actualitzant llistat de fitxers adjunts...";
            $.ajax({ url: attachment_list.dataset.listurl, success: function(data) { 
                attachment_list.innerHTML = data; 
            } });
        });

        const form = document.getElementById('item-attachments');
        form.addEventListener('submit', function(event){
            item_attachments_dz.options.url = event.submitter.form.action;
            console.log("handling form submit..." + event.submitter.form.action);
            if (item_attachments_dz.files.length > 0){
                event.preventDefault();
                event.stopPropagation();
                item_attachments_dz.processQueue();
            }
        });

        return item_attachments_dz;
    }

    reset(){
        this.element.reset();
    }
}