import { Controller } from "@hotwired/stimulus";
import { list } from "postcss";

export default class extends Controller {

    initialize() {

        document.getElementById('agreement_assignable_type').onchange = function() {
            var index      = this.selectedIndex;
            var inputText  = this.children[index].innerHTML.trim();
            var inputValue = this.children[index].value;

            var elems = document.getElementsByClassName("assignable");
            for (var i = 0; i < elems.length; i++) {
                elems[i].setAttribute("disabled", "disabled");
            }
            elems = document.getElementsByClassName("assignable_zone");
            for (var i = 0; i < elems.length; i++) {
                elems[i].classList.add("hidden");
            }
            elems = document.getElementsByClassName("assignable_" + inputValue + "_zone");
            for (var i = 0; i < elems.length; i++) {
                elems[i].classList.remove("hidden");
            }
            document.getElementById('assignable_' + inputValue).removeAttribute('disabled');;
          }

    }
    
}